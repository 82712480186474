

import './ConfirmAccountPage.css'

export default function ConfirmAccountPage() {
    return (
        <div className='confirmpage'>
            <h2 className='font-24' style={{fontWeight: '500'}}>Confirm your account !</h2>
            <p className='font-14' style={{fontWeight: '400'}}>Click on the link sent to your email to continue.</p>
        </div>
    )
}